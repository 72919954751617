<template>
  <div class="releaserecord">
    <table
      class="table"
      width="100%"
      border="1"
      cellspacing="0"
      cellpadding="0"
    >
      <thead>
        <tr class="table-header">
          <th>办理手机号</th>
          <th>套餐内容</th>
          <th>直降金额</th>
          <th>登记时间</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in ShantouOrder.records || []" :key="item.id">
          <td>{{ item.mobileNo }}</td>
          <td>{{ item.setMealContent }}</td>
          <td>{{ item.downAmount }}</td>
          <td>{{ item.registerDate }}</td>
        </tr>
        <!-- <tr>
          <td>15956541200</td>
          <td>150分钟+5G流量</td>
          <td>直降金额</td>
          <td>2020-09-15 15:25:03</td>
        </tr>
        <tr>
          <td>15956541200</td>
          <td>150分钟+5G流量</td>
          <td>直降金额</td>
          <td>2020-09-15 15:25:03</td>
        </tr> -->
      </tbody>
    </table>
    <van-pagination
      v-model="ShantouOrder.current"
      :page-count="ShantouOrder.pages"
      mode="simple"
      @change="changePageMeds"
    />
    <ul class="pagetab">
      <router-link tag="li" :to="{ name: 'ShantouReleasePage' }"
        >下发礼品卡</router-link
      >
      <li>登记记录</li>
    </ul>
  </div>
</template>

<script>
import { reqShantouOrder } from '@/api/shantou-project'
export default {
  name: 'ShantouReleasePage',
  data() {
    return {
      param: {
        pageSize: 10,
        pageNum: 1,
        token: localStorage.getItem('shantouToken') || undefined
      },
      ShantouOrder: {
        records: [],
        current: 1,
        pages: 1
      }
    }
  },
  created() {
    this.getShantouOrderMeds()
  },
  methods: {
    changePageMeds(item) {
      this.param.pageNum = item
      this.getShantouOrderMeds()
    },
    async getShantouOrderMeds() {
      const { data } = await reqShantouOrder(this.param)
      this.ShantouOrder = data
    }
  }
}
</script>
<style lang="less" scoped>
.releaserecord {
  position: relative;
  height: 100vh;
  padding: 2.5vh 0 9vh;
  // padding-top: 5.6vw;
  overflow: auto;
  .table {
    margin: 0 4vw 4vw;
    width: 92vw;
    font-size: 13px;
    color: #5d5d5d;
    border-collapse: collapse;
    border: solid 1px #d2d2d2;
    text-align: center;
    table-layout: fixed;
    word-wrap: break-word;
    .table-header {
      font-size: 12px;
    }
  }
  .pagetab {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 9vh;
    display: flex;
    font-size: 3.73vw;
    & > li {
      flex: 1;
      text-align: center;
      background: #3d6efd;
      color: #fff;
      text-align: center;
      &:first-child {
        background: #fff;
        color: #3d6efd;

        border: 1px solid #3d6efd;
      }
    }
  }
}
</style>
